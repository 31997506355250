<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="flex mb-4 justify-between">
        <router-link
          class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
          :to="{ name: 'list-associates' }"
        >
          <svg
            style="display: initial;"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Voltar
        </router-link>
      </div>
      <div class="p-4 flex justify-between">
        <h1 class="text-3xl">Acessos do associado "{{ associateName }}"</h1>
        <router-link
          class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
          :to="{ name: 'create-associate-access' }"
        >
          Novo Acesso
        </router-link>
      </div>
      <div class="p-4">
        <v-client-table
          ref="table"
          :data="access"
          :columns="columns"
          :options="options"
        >
          <div class="flex" slot="actions" slot-scope="{ row, index }">
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{
                name: 'edit-associate-access',
                params: { id: id, userId: row.id }
              }"
              v-tooltip="{ content: 'Editar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <button
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg"
              @click="deleteAccess(row, index)"
              v-tooltip="{ content: 'Deletar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </button>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ManageAssociateAccess',

  title() {
    return `${process.env.VUE_APP_NAME} | Gerenciar Acessos do Associado`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      associateName: '',
      access: [],
      columns: ['id', 'name', 'email', 'actions'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          document: 'E-mail',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  methods: {
    async fetchAccess() {
      this.isLoading = true;
      await axios
        .get(`/api/associados/${this.id}/usuarios`)
        .then(({ data }) => {
          data.data.forEach(user => {
            this.access.push(user);
          });
        });
      this.isLoading = false;
    },

    fetchAssociate() {
      axios
        .get(`/api/associados/${this.id}`)
        .then(({ data }) => (this.associateName = data.name));
    },

    deleteAccess(row, index) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir este acesso?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .delete(`/api/associados/${this.id}/usuarios/${row.id}`)
              .then(() => {
                this.access.splice(index, 1);
              });
          }
        }
      });
    }
  },

  created: function() {
    this.fetchAccess();
    this.fetchAssociate();
  }
};
</script>

<style scoped></style>
